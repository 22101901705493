import useDetectCountry from '@hooks/useDetectCountry'
import { COUNTRY_WISE_LOCALES } from '@lib/constants/i18n'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Globe } from 'react-feather'

const LanguageModal = dynamic(() =>
  import('@components/common/language-switcher/language-modal')
)

type Props = {
  className?: string
}

const LanguageSwitcher = ({ className }: Props) => {
  const { countryCode: countryCodeFromHook } = useDetectCountry()
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()

  if (
    !Object.keys(COUNTRY_WISE_LOCALES).includes(countryCodeFromHook) &&
    router.locale === router.defaultLocale
  )
    return null

  return (
    <div className={className}>
      <button
        className="lg:py-2 flex items-center lg:space-x-1 text-xl lg:text-lg text-grey hover:text-orange focus:outline-none space-x-1"
        onClick={() => setIsOpen(true)}
      >
        <Globe className="lg:w-auto stroke-1 lg:stroke-2" size={18} />
      </button>
      {isOpen && <LanguageModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  )
}

export default LanguageSwitcher
